import React, { useState,useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useNavigate, useLocation } from 'react-router-dom';
import './Layout.css';
import auth from './Firebase/Auth';
import { UserContext } from './UserContext';
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import { HiClipboardList } from "react-icons/hi";
import { BsEasel2,BsDatabaseFillGear,BsDatabaseGear  } from "react-icons/bs";

const Layout = ({ children }) => {
  // const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1024); // Default: open on large screens, closed on small screens
  // const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024); // Check if it's mobile view
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId, setCurrentUser, setCompanyId,setUserData,userData,newLeaveRequests, setNewLeaveRequests,newOtRequests,setNewOtRequests,setLoading } = useContext(UserContext);
 
  let DEFAULT_LOGO_URL = "https://i.postimg.cc/VLLwZdzX/PAM-logo.png";
  const [logoUrl, setLogoUrl] = useState(DEFAULT_LOGO_URL); // State for logo URL
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    const savedSidebarState = localStorage.getItem('sidebarOpen');
    return savedSidebarState ? JSON.parse(savedSidebarState) : window.innerWidth > 1024;
  });
  
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

  const fetchLogo = async () => {
    if (!companyId) {
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if companyId is not available
      return;
    }
  
    const cachedLogo = localStorage.getItem('logo');
    if (cachedLogo) {
      console.log("logo: ",cachedLogo)
      setLogoUrl(cachedLogo); // Use cached logo if available
      return;
    }
  
    try {
      const logoData = await firestore.getCompanyData(companyId); // Fetch company data from Firestore
      //console.log("logo: ",logoData)
      if (logoData?.logo) {
        setLogoUrl(logoData.logo);
        DEFAULT_LOGO_URL=logoData.logo
        localStorage.setItem('logo', logoData.logo); // Cache the logo in localStorage
      } else {
        setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if Firestore does not have a logo
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo in case of an error
    }
  };

  useEffect(() => {
    fetchLogo(); // Fetch logo URL on component mount
  }, [companyId]);

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const previousLogo = logoUrl; // Save current logo URL before changing
  
    try {
      // Upload the new logo
      const newLogoUrl = await storage.uploadImage(companyId, file);
  
      // Update Firestore with the new logo
      await firestore.updateLogo(companyId, { logo: newLogoUrl });
  
      // Set the new logo in the UI
      setLogoUrl(newLogoUrl);
  
      // Cache the new logo in localStorage
      localStorage.setItem('logo', newLogoUrl);
  
      // Delete the old logo if it's not the default one
      if (previousLogo && previousLogo !== DEFAULT_LOGO_URL) {
        await storage.deleteFile(previousLogo);
      }
  
      // Show success alert
      alert('Logo updated successfully!');
    } catch (error) {
      console.error('Error updating logo:', error);
      alert('Error updating logo. Please try again.');
      setLogoUrl(DEFAULT_LOGO_URL); // Reset to default logo in case of an error
    }
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('sidebarOpen');
    if (savedSidebarState !== null) {
      setSidebarOpen(JSON.parse(savedSidebarState));
    }
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobileView(true);
        setSidebarOpen(false); // Collapse on mobile view
      } else {
        setIsMobileView(false);
        //setSidebarOpen(true); // Expand on large screens
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return; // Early exit if companyId is not valid
    }
  
    // Listen for new leave requests from Firestore
    const unsubscribe = firestore.getAllLeave(companyId, (allLeave) => {
      // Check if there are any requests with state === true
      const hasNewRequests = allLeave.some(leave => leave.state1 === false && leave.deny != true);

      if (hasNewRequests) {
        setNewLeaveRequests(true); // Set notification flag to true when new requests are detected
      } else {
        setNewLeaveRequests(false); // Set it to false if there are no new requests
      }
    }, console.error);

    return () => unsubscribe(); // Unsubscribe from Firestore when the component unmounts
  }, [companyId, setNewLeaveRequests]);

  useEffect(() => {
    // Reset `newLeaveRequests` when "คำขอลางาน" page is active
    if (location.pathname === '/leave_request' && newLeaveRequests) {
      console.log('Resetting newLeaveRequests to false');
      setNewLeaveRequests(false); // Reset flag when user navigates to the leave request page
    }
  }, [location.pathname, newLeaveRequests, setNewLeaveRequests]);

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    // Listen for new leave requests from Firestore
    const unsubscribe = firestore.getAllOT(companyId, (allOt) => {
      // Check if there are any requests with state === true
      const hasNewRequests = allOt.some(ot => ot.state1 === false && ot.deny != true);

      if (hasNewRequests) {
        setNewOtRequests(true); // Set notification flag to true when new requests are detected
      } else {
        setNewOtRequests(false); // Set it to false if there are no new requests
      }
    }, console.error);

    return () => unsubscribe(); // Unsubscribe from Firestore when the component unmounts
  }, [companyId, setNewOtRequests]);

  useEffect(() => {
    // Reset `newLeaveRequests` when "คำขอลางาน" page is active
    if (location.pathname === '/ot_request' && newOtRequests) {
      //console.log('Resetting newLeaveRequests to false');
      setNewOtRequests(false); // Reset flag when user navigates to the leave request page
    }
  }, [location.pathname, newOtRequests, setNewOtRequests]);

  const toggleSidebar = () => {
    const newSidebarState = !sidebarOpen;
    setSidebarOpen(newSidebarState);
    localStorage.setItem('sidebarOpen', JSON.stringify(newSidebarState)); // Store sidebar state
  };

  const logOutSuccess = () => {
    setCurrentUser(null);  // Clear user from context
    setCompanyId(null);    // Clear companyId from context
    setUserData(null);     // Clear userData from context
    localStorage.removeItem('companyId');  // Clear companyId from localStorage
    localStorage.removeItem('userData'); // Clear companyId from localStorage
    localStorage.removeItem('sidebarOpen');
    navigate('/login_company');  // Redirect to login company page
    setLoading(false)
  };

  const logout = (e) => {
    e.preventDefault();
    auth.signOut(logOutSuccess);
  };

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="layout">
      <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-content">
        <div className='head-sidebar'>
            {/* <img src="https://i.postimg.cc/VLLwZdzX/PAM-logo.png" width={80} height={80} style={{marginRight:20}} alt="Logo" /> */}
            <div className="logo-container">
              <img src={logoUrl} width={80} height={80} alt="Logo" />
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                style={{ display: 'none' }}
                id="logoUpload"
              />
              <label htmlFor="logoUpload" className="change-logo-icon">
                <i className="fas fa-camera"></i> {/* FontAwesome camera icon */}
              </label>
            </div>
            {sidebarOpen && <h4>Personnel Assistance Manager</h4>}
          </div>
          <nav>
          <ul>
              <li className={isActive("/home") ? "active" : ""}>
                <Link to="/home">
                  <i className="fas fa-home"></i> {/* Home Icon */}
                  <span>สรุปภาพรวมข้อมูล</span>
                </Link>
              </li>
              <li className={isActive("/manageIndex") ? "active" : ""}>
                <Link to="/manageIndex">
                  <BsDatabaseGear size={28} style={{marginLeft:-5}}/> {/* Database Icon */}
                  <span>การตั้งค่าพื้นฐาน</span>
                </Link>
              </li>

              <li className={isActive("/profile") ? "active" : ""}>
                <Link to="/profile">
                  <i className="fas fa-user"></i>
                  <span>การจัดการข้อมูลพนักงาน</span>
                </Link>
              </li>
              <li className={isActive("/welthfare") ? "active" : ""}>
                <Link to="/welthfare">
                  <i className="fas fa-calendar-check"></i> {/* Calendar Check Icon */}
                  <span>การจัดการสิทธิ์และวันหยุด</span>
                </Link>
              </li>
              <li className={isActive("/managment") ? "active" : ""}>
                <Link to="/managment">
                  <i className="fas fa-users"></i> {/* Users Icon */}
                  <span>การบริหารพื้นที่ปฏิบัติการ</span>
                </Link>
              </li>
              <li className={isActive("/salary") ? "active" : ""}>
                <Link to="/salary">
                  <i className="fas fa-money-bill-wave"></i> {/* Money Icon */}
                  <span>การจัดการเงินเดือน</span>
                </Link>
              </li>
              <li className={isActive("/checkin_history") ? "active" : ""}>
                <Link to="/checkin_history">
                  <i className="fas fa-clock"></i> {/* Clock Icon */}
                  <span>ประวัติการเข้า-ออกงาน</span>
                </Link>
              </li>
              <li className={isActive("/asset_sync") ? "active" : ""}>
                <Link to="/asset_sync">
                  <HiClipboardList size={30} style={{marginLeft:-5}}/> {/* Calendar Icon */}
                  <span>Asset Sync</span>
                </Link>
              </li>
              <li className={isActive("/leave_request") ? "active" : ""}>
                <Link to="/leave_request">
                  <i className="fas fa-suitcase"></i> {/* Suitcase Icon */}
                  <span>การขอลางาน</span>
                  {newLeaveRequests && <span className="notification-dot"></span>}
                </Link>
              </li>
              <li className={isActive("/ot_request") ? "active" : ""}>
                <Link to="/ot_request">
                  <i className="fas fa-hourglass-half"></i> {/* Hourglass Icon */}
                  <span>การขอทำงานล่วงเวลา</span>
                  {newOtRequests && <span className="notification-dot"></span>}
                </Link>
              </li>
              <li className={isActive("/annouce") ? "active" : ""}>
                <Link to="/annouce">
                  <i className="fas fa-bullhorn"></i> {/* Announcement Icon */}
                  <span>การประกาศข่าวสาร</span>
                </Link>
              </li>
              <li className={isActive("/2_annouce_extend") ? "active" : ""}>
                <Link to="/2_annouce_extend">
                  <i className="fas fa-newspaper"></i> {/* Envelope Icon */}
                  <span>ประกาศหน้า 2</span>
                </Link>
              </li>
              <li className={isActive("/acknowledge") ? "active" : ""}>
                <Link to="/acknowledge">
                  <BsEasel2 size={28}/> {/* Calendar Icon */}
                  <span>Acknowledge</span>
                </Link>
              </li>
              <li className={isActive("/network") ? "active" : ""}>
                <Link to="/network">
                  <i className="fas fa-network-wired"></i> {/* Network Icon */}
                  <span>เครือข่ายภายใน</span>
                </Link>
              </li>
              <li className={isActive("/calendar") ? "active" : ""}>
                <Link to="/calendar">
                  <i className="fas fa-calendar"></i> {/* Calendar Icon */}
                  <span>ปฏิทินบันทึกโน้ต</span>
                </Link>
              </li>
              
              <li className={isActive("/manage_account") ? "active" : ""}>
                <Link to="/manage_account">
                  <i className="fas fa-cogs"></i> {/* Settings Icon */}
                  <span>การจัดการผู้ใช้งาน</span>
                </Link>
              </li>
              <li className={isActive("/contact") ? "active" : ""}>
                <Link to="/contact">
                  <i className="fas fa-envelope"></i> {/* Envelope Icon */}
                  <span>ติดต่อผู้พัฒนาโปรแกรม</span>
                </Link>
              </li>
            </ul>
          </nav>
          <div className='logout-button'> 
          <button style={{ fontSize: 22 }} onClick={logout}>
              <i style={{marginRight:5}} className="fas fa-sign-out-alt"></i> {/* Sign Out Icon */}
              {sidebarOpen && "ออกจากระบบ"}
            </button>
          </div>
        </div>
      </div>
      
        
        <button className="toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
          
       
        <div className="content">
          {children}
        </div>
      </div>
    
  );
};

export default Layout;

import React, { useContext, useEffect, useState } from 'react';
import './Home.css'; // Your custom CSS file
import Layout from './Layout';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { UserContext } from './UserContext';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import firestore from './Firebase/Firestore';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

function Home() {
  const { setCurrentUser, companyId, userData } = useContext(UserContext);
  const [workplaceUserData, setWorkplaceUserData] = useState([]);
  const [departmentData, setDepartmentData] = useState({});
  const [nationalData, setNationalData] = useState({});
  const [employmentData, setEmploymentData] = useState({});
  const [generationData, setGenerationData] = useState({});
  const [leaveData, setLeaveData] = useState({ working: 0, leave: { 'ลากิจ': 0, 'ป่วย': 0, 'พักร้อน': 0 } });
  const [employeeLeaveData, setEmployeeLeaveData] = useState([]);
  const [barData1, setBarData1] = useState({});
  const [barData2, setBarData2] = useState({}); 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const generateSummary = (labels, data, total) => {
    return labels
      .map((label, index) => `${label} [${data[index]} / ${(data[index] / total * 100).toFixed(2)}%],`)
      .join(' ');
  };
  
  // Summarize pieData1 (Department Data)
  const pieData1Labels = Object.keys(departmentData);
  const pieData1Values = Object.values(departmentData);
  const pieData1Total = pieData1Values.reduce((acc, val) => acc + val, 0);
  const pieData1Summary = generateSummary(pieData1Labels, pieData1Values, pieData1Total);

  const absentCount = Math.max(
    0,
    totalUsers
      - leaveData.working
      - leaveData.leave['ลากิจ']
      - leaveData.leave['ลาป่วย']
      - leaveData.leave['ลาพักร้อน']
  );
  // Summarize pieData2 (Work and Leave Summary)
  const pieData2Labels = ['เข้าทำงาน', 'ลากิจ', 'ลาป่วย', 'ลาพักร้อน',];
  const pieData2Values = [
    leaveData.working,
    leaveData.leave['ลากิจ'],
    leaveData.leave['ลาป่วย'],
    leaveData.leave['ลาพักร้อน'],
    absentCount,
  ];
  const pieData2Total = pieData2Values.reduce((acc, val) => acc + val, 0);
  const pieData2Summary = generateSummary(pieData2Labels, pieData2Values, pieData2Total);

  // Summarize otherPieChartData (Workplace Data)
  const otherPieChartLabels = workplaceUserData.map((workplace) => workplace.name);
  const otherPieChartValues = workplaceUserData.map((workplace) => workplace.count);
  const otherPieChartTotal = otherPieChartValues.reduce((acc, val) => acc + val, 0);
  const otherPieChartSummary = generateSummary(otherPieChartLabels, otherPieChartValues, otherPieChartTotal);

  const slides = [
    { title: "สรุปรายละเอียดพนักงานทั้งหมด", content: pieData1Summary },
    { title: "สรุปรายละเอียดการเข้าทำงาน", content: pieData2Summary },
    { title: "สรุปการจัดการกำลังพล", content: otherPieChartSummary },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const nationalDataLabels = Object.keys(nationalData);
  const nationalDataValues = Object.values(nationalData);
  const nationalDataTotal = nationalDataValues.reduce((acc, val) => acc + val, 0);
  const nationalDataSummary = generateSummary(nationalDataLabels, nationalDataValues, nationalDataTotal);

  const employmentDataLabels = Object.keys(employmentData);
  const employmentDataValues = Object.values(employmentData);
  const employmentDataTotal = employmentDataValues.reduce((acc, val) => acc + val, 0);
  const employmentDataSummary = generateSummary(employmentDataLabels, employmentDataValues, employmentDataTotal);

  const generationDataLabels = Object.keys(generationData);
  const generationDataValues = Object.values(generationData);
  const generationDataTotal = generationDataValues.reduce((acc, val) => acc + val, 0);
  const generationDataSummary = generateSummary(generationDataLabels, generationDataValues, generationDataTotal);


  const generateColors = (length) => {
    // Define a base color palette
    const baseColors = ['#4caf50', '#ff9800', '#f44336', '#3f51b5', '#9c27b0', '#00acc1', '#8bc34a', '#cddc39', '#ff5722', '#607d8b'];
  
    // If length is less than or equal to baseColors, return the base colors slice
    if (length <= baseColors.length) {
      return baseColors.slice(0, length);
    }
  
    // Otherwise, generate additional random colors to fill the gap
    const randomColors = Array.from({ length: length - baseColors.length }, () => {
      // Generate random hex color
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    });
  
    // Concatenate base colors with the newly generated random colors
    return [...baseColors, ...randomColors];
  };

  const countEmployeesByDepartment = (employees) => {
    const departmentCounts = {};
    employees.forEach((user) => {
      const department = user.department?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      departmentCounts[department] = (departmentCounts[department] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (departmentCounts['อื่นๆ']) {
      const othersCount = departmentCounts['อื่นๆ'];
      delete departmentCounts['อื่นๆ']; // Remove it from its original position
      departmentCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return departmentCounts;
  };

  const countNational = (employees) => {
    const nationalCounts = {};
    employees.forEach((user) => {
      const national = user.national?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      nationalCounts[national] = (nationalCounts[national] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (nationalCounts['อื่นๆ']) {
      const othersCount = nationalCounts['อื่นๆ'];
      delete nationalCounts['อื่นๆ']; // Remove it from its original position
      nationalCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return nationalCounts;
  };

  const countEmployment = (employees) => {
    const employmentCounts = {};
    employees.forEach((user) => {
      const employment = user.employment?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      employmentCounts[employment] = (employmentCounts[employment] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (employmentCounts['อื่นๆ']) {
      const othersCount = employmentCounts['อื่นๆ'];
      delete employmentCounts['อื่นๆ']; // Remove it from its original position
      employmentCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return employmentCounts;
  };

  const countGeneration = (employees) => {
    const generationCounts = {};
    employees.forEach((user) => {
      const generation = user.generation?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      generationCounts[generation] = (generationCounts[generation] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (generationCounts['อื่นๆ']) {
      const othersCount = generationCounts['อื่นๆ'];
      delete generationCounts['อื่นๆ']; // Remove it from its original position
      generationCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return generationCounts;
  };

  const parseBirthDay = (birthDayString) => {
    if (!birthDayString) return null;
    const [day, month, year] = birthDayString.split('/');
    if (!day || !month || !year) return null;
    return new Date(+year, +month - 1, +day);
  };

  const getAge = (dob) => {
    if (!dob) return null;
    const diffMs = Date.now() - dob.getTime();
    if (diffMs < 0) return null; // หากเป็นอนาคตหรือไม่สมเหตุสมผล
    const ageDate = new Date(diffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const countAgeDistribution = (users) => {
    const distribution = {
      "<20": 0,
      "20-29": 0,
      "30-39": 0,
      "40-49": 0,
      "50-59": 0,
      "60+": 0,
      "ไม่ระบุ": 0,
    };

    users.forEach((user) => {
      const dob = parseBirthDay(user.birthDay); // birthDay เป็น "dd/mm/yyyy"
      const age = getAge(dob);

      if (age === null) {
        distribution["ไม่ระบุ"]++;
      } else if (age < 20) {
        distribution["<20"]++;
      } else if (age >= 20 && age <= 29) {
        distribution["20-29"]++;
      } else if (age >= 30 && age <= 39) {
        distribution["30-39"]++;
      } else if (age >= 40 && age <= 49) {
        distribution["40-49"]++;
      } else if (age >= 50 && age <= 59) {
        distribution["50-59"]++;
      } else {
        // 60+
        distribution["60+"]++;
      }
    });

    return distribution;
  };

  const [ageDistribution, setAgeDistribution] = useState({
    "<20": 0,
    "20-29": 0,
    "30-39": 0,
    "40-49": 0,
    "50-59": 0,
    "60+": 0,
    "ไม่ระบุ": 0,
  });

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    // Fetch workplace user count data
    //console.log(userData.position)
    firestore.getWorkplaceUserCounts(
      companyId,
      (data) => {setWorkplaceUserData(data); console.log(data)},  // On success, set the state
      (error) => console.error('Error fetching workplace data:', error) // On error
    );
    firestore.getAllUser(
      companyId,
      (users) => {
        setTotalUsers(users.length);
        const counts = countEmployeesByDepartment(users);
        setDepartmentData(counts); // Set the department counts in state
        const counts_national = countNational(users);
        setNationalData(counts_national); 
        const counts_employment = countEmployment(users);
        setEmploymentData(counts_employment); 
        const counts_generation = countGeneration(users);
        setGenerationData(counts_generation); 
      },
      (error) => {
        console.error('Error fetching employee data:', error);
      }
    );
    firestore.getWorkAndLeaveDataForCurrentDate(
      companyId,
      (data) => {
        //console.log("Fetched leave data: ", data); 
        setLeaveData(data); // On success, set the data
      },
      (error) => console.error('Error fetching work and leave data:', error) // Handle error
    );
  }, [companyId]);

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    // Fetch all users and then fetch their leave data from wealthfare collection
    firestore.getAllUser(
      companyId,
      (users) => {
        console.log("Fetched users:", users);
  
        const leaveDataPromises = users.map((user) =>
          new Promise((resolve) => {
            firestore.getAllLeaveDataMtoL(
              companyId, 
              user.id, 
              (leaveData) => {
                const absenceUsed = Math.max(0, leaveData.absence - leaveData.absenceR);
                const sickUsed = Math.max(0, leaveData.sick - leaveData.sickR);
                const holidayUsed = Math.max(0, leaveData.holiday - leaveData.holidayR);
                const attendenceCount = Math.max(0, leaveData.attendence);
  
                resolve({
                  name: user.name,
                  image: user.image_off,
                  absenceUsed,
                  sickUsed,
                  holidayUsed,
                  attendenceCount,
                  totalLeaveUsed: absenceUsed + sickUsed + holidayUsed + attendenceCount,
                });
              },
              (error) => {
                console.warn(`Skipping user ${user.id} due to missing data: ${error}`);
                resolve(null); // Resolve with null if wealthfare document is missing
              }
            );
          })
        );
  
        Promise.all(leaveDataPromises).then((leaveDataResults) => {
          const filteredData = leaveDataResults.filter(data => data !== null);
  
          // Process barData1 (Most to Least)
          const top10LeaveData = filteredData
            .sort((a, b) => b.totalLeaveUsed - a.totalLeaveUsed)
            .slice(0, 10);
  
          setEmployeeLeaveData(top10LeaveData);
  
          if (top10LeaveData.length > 0) {
            setBarData1({
              labels: top10LeaveData.map(employee => employee.name),
              datasets: [
                {
                  label: 'ลาพักร้อน',
                  backgroundColor: '#508C9B',
                  data: top10LeaveData.map(employee => employee.holidayUsed),
                },
                {
                  label: 'ลาป่วย',
                  backgroundColor: '#134B70',
                  data: top10LeaveData.map(employee => employee.sickUsed),
                },
                {
                  label: 'ลากิจ',
                  backgroundColor: '#201E43',
                  data: top10LeaveData.map(employee => employee.absenceUsed),
                },
                {
                  label: 'ขาดงาน',
                  backgroundColor: '#351c75',
                  data: top10LeaveData.map(employee => employee.attendenceCount),
                },
              ],
              users: top10LeaveData,
            });
          }
  
          // Process barData2 (Least to Most)
          const bottom10LeaveData = filteredData
            .sort((a, b) => a.totalLeaveUsed - b.totalLeaveUsed)
            .slice(0, 10);
  
          if (bottom10LeaveData.length > 0) {
            setBarData2({
              labels: bottom10LeaveData.map(employee => employee.name),
              datasets: [
                {
                  label: 'ลาพักร้อน',
                  backgroundColor: '#D5DEF5',
                  data: bottom10LeaveData.map(employee => employee.holidayUsed),
                },
                {
                  label: 'ลาป่วย',
                  backgroundColor: '#8594E4',
                  data: bottom10LeaveData.map(employee => employee.sickUsed),
                },
                {
                  label: 'ลากิจ',
                  backgroundColor: '#6643B5',
                  data: bottom10LeaveData.map(employee => employee.absenceUsed),
                },
                {
                  label: 'ขาดงาน',
                  backgroundColor: '#0b5394',
                  data: bottom10LeaveData.map(employee => employee.attendenceCount),
                },
              ],
              users: bottom10LeaveData,
            });
          }
        });
      },
      (error) => console.error('Error fetching users:', error)
    );

    firestore.getAllUser(
      companyId,
      (users) => {
        const distribution = countAgeDistribution(users);
        setAgeDistribution(distribution);
      },
      (error) => {
        console.error('Error fetching employee data:', error);
      }
    );
  }, [companyId]);

  // const customTooltipPlugin = {
  //   id: 'customTooltipPlugin',
  //   beforeDraw: (chart) => {
  //     const ctx = chart.ctx;
  //     const users = chart.config.data.users || [];
  //     const meta = chart.getDatasetMeta(0);

  //     meta.data.forEach((bar, index) => {
  //       const user = users[index];
  //       if (user) {
  //         const image = new Image();
  //         image.src = user.image;
  //         const x = bar.x - 15;
  //         const y = chart.chartArea.bottom + 10;

  //         image.onload = () => {
  //           ctx.drawImage(image, x, y, 30, 30);
  //         };
  //       }
  //     });
  //   },
  // };

  // Chart.register(customTooltipPlugin);
  
  const valueLabelPlugin = {
    id: 'valueLabelPlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx } = chart;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const value = dataset.data[index];
          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = '#000'; // Text color
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          ctx.fillText(value, bar.x, bar.y - 5); // Position text above the bar
          ctx.restore();
        });
      });
    },
  };
  
  Chart.register(valueLabelPlugin);
  

  const pieData1 = {
    labels: pieData1Labels,
    datasets: [
      {
        label: 'จำนวนพนักงาน',
        data: Object.values(departmentData),
        backgroundColor: generateColors(Object.keys(departmentData).length),
        hoverBackgroundColor: generateColors(Object.keys(departmentData).length),
      },
    ],
  };

  //const pieData2Labels = ['เข้าทำงาน', 'ลากิจ', 'ลาป่วย', 'ลาพักร้อน'];
  const pieData2 = {
    labels: pieData2Labels,
    datasets: [
      {
        label: 'สรุปประจำวัน',
        data: [
          leaveData.working,
          leaveData.leave['ลากิจ'],
          leaveData.leave['ลาป่วย'],
          leaveData.leave['ลาพักร้อน'],
          absentCount,
        ],
        backgroundColor: generateColors(pieData2Labels.length),
        hoverBackgroundColor: generateColors(pieData2Labels.length),
      },
    ],
  };

  // Prepare the data for "จำนวนคนอื่นๆ" pie chart
  const otherPieChartData = {
    labels: otherPieChartLabels, // Workplace names
    datasets: [
      {
        label: 'จำนวนคนตามพื้นที่ทำงาน',
        data: otherPieChartValues, // Number of users in each workplace
        backgroundColor: generateColors(workplaceUserData.length),
        hoverBackgroundColor: generateColors(workplaceUserData.length),
      },
    ],
  };

  const nationalChart = {
    labels: nationalDataLabels,
    datasets: [
      {
        label: 'สัญชาติพนักงาน',
        data: Object.values(nationalData),
        backgroundColor: generateColors(Object.keys(nationalData).length),
        hoverBackgroundColor: generateColors(Object.keys(nationalData).length),
      },
    ],
  };

  const employmentChart = {
    labels: employmentDataLabels,
    datasets: [
      {
        label: 'ประเภทการจ้าง',
        data: Object.values(employmentData),
        backgroundColor: generateColors(Object.keys(employmentData).length),
        hoverBackgroundColor: generateColors(Object.keys(employmentData).length),
      },
    ],
  };

  const generationChart = {
    labels: generationDataLabels,
    datasets: [
      {
        label: 'สัดส่วน Generation',
        data: Object.values(generationData),
        backgroundColor: generateColors(Object.keys(generationData).length),
        hoverBackgroundColor: generateColors(Object.keys(generationData).length),
      },
    ],
  };

  const ageLabels = Object.keys(ageDistribution);   // ["<20", "20-29", ...]
  const ageValues = Object.values(ageDistribution); // [จำนวน, จำนวน, ...]

  const ageChartData = {
    labels: ageLabels,
    datasets: [
      {
        label: 'จำนวนพนักงานตามช่วงอายุ',
        data: ageValues,
        backgroundColor: generateColors(ageLabels.length),
        hoverBackgroundColor: generateColors(ageLabels.length),
      },
    ],
  };

  return (
    <div className="dashboard">
      <Layout />
      
      <main className="dashboard-contain">
        {/* First Row: Two Pie Charts */}
        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>จำนวนพนักงาน</p>
            <Bar
              data={pieData1}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สรุปประจำวัน</p>
            {/* <Pie
              data={pieData2}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={pieData2}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>

        {/* Second Row: One Pie Chart and One Container */}
        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>จัดการกำลังคน</p>
            {/* <Pie
              data={otherPieChartData}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={otherPieChartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
         
          <div className="chart-container">
            <p className="data-title">Data Visualization</p>

            <div className="slideDash-container">
              <button onClick={prevSlide} className="slide-nav-btn">◀</button>

              <div className="slide">
                <p className="slideDash-title">{slides[currentSlide].title}</p>
                <p className="slideDash-content">{slides[currentSlide].content}</p>
              </div>

              <button onClick={nextSlide} className="slide-nav-btn">▶</button>
            </div>
          </div>
        
        </div>

        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>ช่วงอายุ</p>
            <Bar
              data={ageChartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สัญชาติพนักงาน</p>
            <Bar
              data={nationalChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>

        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>ประเภทการจ้าง</p>
            <Bar
              data={employmentChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สัดส่วน Generation</p>
            {/* <Pie
              data={pieData2}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={generationChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
              }}
              plugins={[valueLabelPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>


        {/* Third Row: First Bar Chart Most Leave*/}
        <div className="chart-row">
          <div className="chart-container bar-chart-fullwidth">
            {barData1 && barData1.labels ? (
              <Bar
                data={barData1}
                options={{ maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 20, // Add extra padding to the top
                    },
                  },
                 }}
                //plugins={[customTooltipPlugin]} // Register image plugin
                style={{ width: '800px', height: '400px' }}
                
              />
            ) : (
              <p>Loading bar chart...</p>
            )}
          </div>
        </div>

        {/* Fourth Row: Second Bar Chart Least Leave*/}
        <div className="chart-row">
          <div className="chart-container bar-chart-fullwidth">
            {barData2 && barData2.labels ? (
              <Bar
                data={barData2}
                options={{ maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 20, // Add extra padding to the top
                    },
                  },
                 }}
                //plugins={[customTooltipPlugin]} // Register image plugin
                style={{ width: '800px', height: '1000px' }}
              />
            ) : (
              <p>Loading bar chart...</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;

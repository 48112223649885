// ApprovePlan.js
import React,{ useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import logo from './icon/PAM_logo.png';
import { AdminProvider, AdminContext } from './AdminContext';
import adminAuth from './Firebase/AdminAuth';

const Navbar = () => {
  const navigate = useNavigate();
  const { currentAdmin, setCurrentAdmin ,loadingAdmin, setLoadingAdmin , } = useContext(AdminContext);

  const logOutSuccess = () => {
    setCurrentAdmin(null);
    setLoadingAdmin(false)
    localStorage.removeItem('adminData');
    navigate('/login_admin');  // Redirect to login company page
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="PAM Logo" className="navbarlogo" />
      </div>
      <div className="button-container">
      <button className="nav-button" onClick={() => navigate('/register')}>Sign Up</button>
      <button className="nav-button" onClick={() => adminAuth.signout(logOutSuccess)}>Sign Out</button>
      </div>
    </div>
  );
};

const ApprovePlan = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const { currentAdmin, setCurrentAdmin ,loadingAdmin, setLoadingAdmin , } = useContext(AdminContext);

  const logOutSuccess = () => {
    setCurrentAdmin(null);
    setLoadingAdmin(false)
    localStorage.removeItem('adminData');
    navigate('/login_admin');  // Redirect to login company page
  };
  return (
    <div className="service-container">
      <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container">
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
          </div>
          <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
           
          >
            Plans
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
           
          >
            Applications
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
           
          >
            For Admin
          </button>
        </div>
        <div className="button-container">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/register')}>Sign Up</button>
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={() => adminAuth.signout(logOutSuccess)}>Sign Out</button>
        </div>
      </nav>
      <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
        <p style={{fontSize:56}}>Soon...</p>
      </div>
      
      {/* </div> */}
    </div>
  );
};

export default ApprovePlan;

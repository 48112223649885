import './App.css';
import React, { useState, useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import firestore from './Firebase/Firestore';
import auth from './Firebase/Auth';

const GetApk = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState('');
  const { setCompanyId: setContextCompanyId } = useContext(UserContext);

  const onVerify = async (e) => {
    e.preventDefault();

    const success = (found) => {
      if (found) {
        setContextCompanyId(companyId);
        localStorage.setItem('companyId', companyId); // Save companyId to localStorage

        // Direct download link
        const downloadUrl = 'https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/application-c015c010-0484-4709-ad11-162c7b5fc36f.apk?alt=media&token=8fa686b0-b82a-42f2-a7ea-ed7fa46058c3';

        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'PAM_HR_v1.0.6.19.271267.apk'; // Optional: specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // navigate('/login');
      } else {
        alert('Company not found!');
      }
    };

    const unsuccess = () => {
      alert('Company not found!');
    };

    firestore.checkCompany(companyId, success, unsuccess);
  };

  const register = () => {
    navigate('/register')
  };

//   useEffect(() => {
//     auth.checksignin((user) => {
//       if (user) {
//         firestore.getAccount(companyId,user.id,(data)=>console.log(data),(e)=>console.log(e))
//         navigate('/home');
//       }
//     });
//   }, [navigate]);

  return (
    <div className="App">
      <header className="App-header">
        <div className='Main'>
          <img src='https://i.postimg.cc/VLLwZdzX/PAM-logo.png' width={200} height={200} alt="Logo" />
          <form onSubmit={onVerify}>
            <input
              style={{ marginTop: 50, textAlign: companyId ? 'left' : 'center'}}
              className="input-field"
              placeholder="Company ID"
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              required
              autoComplete="off"
            />
            {/* <div style={{ textAlign: 'right',marginRight:8}}>
            <p className="register">
              ถ้าคุณยังไม่มีบัญชี{' '}
              <span className="register-link" onClick={register}>ลงทะเบียน</span>
            </p>
            </div> */}
            <button type="submit" className="login-button" style={{marginTop:30}}>Download</button>
          </form>
        </div>
      </header>
    </div>
  );
};

export default GetApk;

import { initializeApp } from "firebase/app";
import {getAuth } from "firebase/auth";

const config = {
    apiKey: "AIzaSyBsp1zwRSH_PbMnuvzXSAyGg0JCMtb5JnQ",
    authDomain: "pamproject-a57c5.firebaseapp.com",
    projectId: "pamproject-a57c5",
    storageBucket: "pamproject-a57c5.appspot.com",
    messagingSenderId: "380569338900",
    appId: "1:380569338900:web:1bcbb280dae2f867a49301",
    measurementId: "G-QNF2C257P7"
  };

// อันใหม่สำหรับเทส
// const config = {
//   apiKey: "AIzaSyB9xHakw0NgeDjK6eKXIa-7j_ejFk8-o8o",
//   authDomain: "pam-hr.firebaseapp.com",
//   projectId: "pam-hr",
//   storageBucket: "pam-hr.firebasestorage.app",
//   messagingSenderId: "268448308622",
//   appId: "1:268448308622:web:4cf22f913cb9360a2218df",
//   measurementId: "G-68V2SPHV0M"
// };

const app = initializeApp(config);
const auth = getAuth(app);
// Initialize Firebase Authentication and get a reference to the service
export {auth};
export default app;

  
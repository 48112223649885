import React, { useState,useRef,useEffect,useContext,useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import '../Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import '../addProfile.css'
import { Alert, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import firestore from '../Firebase/Firestore';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Layout from '../Layout';
import { Label } from '@mui/icons-material';
import { UserContext } from '../UserContext';
import { count } from 'firebase/firestore';
import { IoMagnet } from 'react-icons/io5';

import storage from '../Firebase/Storage';
import { useDropzone } from 'react-dropzone';

function ProfileUpSk() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentUser, companyId } = useContext(UserContext);
  const [uid,setUid] = useState('');
  const [linkAi,setLinkAi] = useState('');
  const [linkSoft,setLinkSoft] = useState('');
  const [linkWeld,setLinkWeld] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [imageAiFile,setImageAiFile] = useState('');
  const [imageSoftFile,setImageSoftFile] = useState('');
  const [imageWeldFile,setImageWeldFile] = useState('');
  const [imageAiFileOld,setImageAiFileOld] = useState('');
  const [imageSoftFileOld,setImageSoftFileOld] = useState('');
  const [imageWeldFileOld,setImageWeldFileOld] = useState('');
  const [imageAiName,setImageAiName] = useState('');
  const [imageSoftName,setImageSoftName] = useState('');
  const [imageWeldName,setImageWeldName] = useState('');

  const [files, setFiles] = useState([null, null, null, null]);
  const [files1, setFiles1] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert("You can only upload one file.");
      return;
    }
    
    const isImage = acceptedFiles.every((file) => file.type.startsWith("image/"));
    if (!isImage) {
      alert("Only image files are allowed.");
      return;
    }
  
    setFiles1(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept only image files
    maxFiles: 1,      // Limit to one file
  });


  const SaveSuc=()=> {
    alert('Save data success!!')
    navigate('/profile', { state: { startIndex, endIndex } })
  };
  const SaveUnsuc=(e)=>{
    try{
        let item= {
            linkAi:linkAi,
            linkSoft:linkSoft,
            linkWeld:linkWeld,
            newRead:true,
            imageAiName: "",
            imageSoftName: "",
            imageWeldName:  "",
            imageAiFile: "",
            imageSoftFile:  "",
            imageWeldFile:  "",
         }
        firestore.addUpSkill(companyId,uid,item,()=>navigate('/profile', { state: { startIndex, endIndex } }),(e)=>console.log(e))
    }catch{
        console.log(e)
    }
  }
  
  const onSave= async()=>{
    if(!imageAiFile){
      storage.deleteFile(imageAiFileOld)
    }
    if(!imageSoftFile){
      storage.deleteFile(imageSoftFileOld)
    }
    if(!imageWeldFile){
      storage.deleteFile(imageWeldFileOld)
    }
    const uploadImageIfChanged = async (file, oldName, oldUrl) => {
      if (!file) {
        // ไม่มีการเปลี่ยนแปลง ใช้ข้อมูลเดิม
        return { name: oldName, url: oldUrl };
      } else {
        // อัปโหลดไฟล์ใหม่
        const uploaded = await new Promise((resolve, reject) => {
          storage.uploadFile(
            companyId,
            file,
            (progress) => {
              // handle progress
            },
            (downloadURL) => {
              resolve({ name: file.name, url: downloadURL });
            },
            (error) => reject(error)
          );
        });
        if (oldUrl && oldUrl.trim() !== "") {
          await storage.deleteFile(oldUrl);
        }
        return uploaded;
      }
    };
    // Upload images for Relaxation
    const uploaded1 = await uploadImageIfChanged(files[0], imageAiName, imageAiFile);
    const uploaded2 = await uploadImageIfChanged(files[1], imageSoftName, imageSoftFile);
    const uploaded3 = await uploadImageIfChanged(files[2], imageWeldName, imageWeldFile);
    let item = {
      linkAi:linkAi,
      linkSoft:linkSoft,
      linkWeld:linkWeld,
      newRead:true,
      imageAiName: uploaded1.name,
      imageSoftName: uploaded2.name,
      imageWeldName: uploaded3.name,
      imageAiFile: uploaded1.url,
      imageSoftFile: uploaded2.url,
      imageWeldFile: uploaded3.url,
    };
    firestore.updateUpskill(companyId,uid,item,SaveSuc,SaveUnsuc)
  }

  const removeFile = (file) => () => {
    setFiles(files.filter((f) => f !== file));
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[file.name];
      return newProgress;
    });
  };

  const getUserSuccess=(data)=>{
    setLinkAi(data.linkAi || '')
    setLinkSoft(data.linkSoft || '')
    setLinkWeld(data.linkWeld || '')
    setImageAiName(data.imageAiName || null);
    setImageSoftName(data.imageSoftName || null);
    setImageWeldName(data.imageWeldName || null);

    setImageAiFile(data.imageAiFile || null);
    setImageSoftFile(data.imageSoftFile || null);
    setImageWeldFile(data.imageWeldFile || null);
    setImageAiFileOld(data.imageAiFile || null);
    setImageSoftFileOld(data.imageSoftFile || null);
    setImageWeldFileOld(data.imageWeldFile || null);
  }

  const getUserUnsuccess=(e)=> {console.log(e)}

  const handleFileChange = (index, file) => {
    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };
  
  // การ render แต่ละช่อง
  const imageSlots = [
    { index: 0, name: imageAiName, url: imageAiFile, setName: setImageAiName, setUrl: setImageAiFile },
    { index: 1, name: imageSoftName, url: imageSoftFile, setName: setImageSoftName, setUrl: setImageSoftFile },
    { index: 2, name: imageWeldName, url: imageWeldFile, setName: setImageWeldName, setUrl: setImageWeldFile },
  ];

  useEffect(() => {
    if (location.state && location.state.uid) {
        setUid(location.state.uid);
        firestore.getUpSkill(companyId,location.state.uid, getUserSuccess, getUserUnsuccess)
      } else {
        console.warn('No ID found in location state');
      }
      if (location.state) {
        setStartIndex(location.state.startIndex || 0);
        setEndIndex(location.state.endIndex || 10);
      }
  }, [location.state]);

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>องค์ความรู้เพิ่มทักษะ</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%',marginTop:30}}>
              
              {imageSlots.map((slot, i) => {
                // ถ้า user ยังไม่ได้เลือกไฟล์ใหม่ (files[i]) แต่มีไฟล์เก่าใน slot.url => ใช้ชื่อไฟล์เก่า
                const displayName = files[i]?.name || slot.name || `Select Image ${i + 1}`;

                return (
                  <div
                    key={i}
                    // ปรับเป็น column
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      width: '100%', 
                      marginBottom: 10 
                    }}
                  >
                    {/* Part 1: สมมติเป็น Title หรือ Link ฯลฯ */}
                    <div className="form-row" style={{ marginBottom: '20px' }}>
                      <p
                        style={{
                          fontSize: 28,
                          backgroundColor: '#D3D3D3',
                          width: '100%',
                          alignSelf: 'center',
                          borderLeft: '5px solid black',
                          borderRadius: 5,
                          paddingLeft: 5,
                        }}
                      >
                        Knowledge - {i + 1}
                      </p>
                    </div>
                    <div className="form-row" style={{ marginBottom: '20px' }}>
                      <TextField
                        className="form-field"
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        label="แนบลิงค์"
                        variant="filled"
                        style={{ width: '100%' }}
                        // ตัวอย่าง: เปลี่ยน slot ให้รองรับลิงก์แยกกัน (ในตัวอย่างเดิมอาจยังรวมกัน)
                        value={
                          i === 0 ? linkAi : 
                          i === 1 ? linkSoft : 
                          i === 2 ? linkWeld : ""
                        }
                        onChange={(e) => {
                          if (i === 0) setLinkAi(e.target.value);
                          else if (i === 1) setLinkSoft(e.target.value);
                          else if (i === 2) setLinkWeld(e.target.value);
                        }}
                      />
                      
                    </div>
                    <div className="form-row" style={{ marginBottom: '20px' }}>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' ,width:'100%'}}
                        id={`relax-pic-input-${i}`}
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            // ล้างค่ารูปเก่าใน slot.url
                            slot.setUrl("");
                            // ตั้งชื่อไฟล์ใหม่
                            slot.setName(e.target.files[0].name);
                            // อัปเดตไฟล์ใน state files
                            handleFileChange(i, e.target.files[0]);
                          }
                        }}
                      />
                      <label
                        htmlFor={`relax-pic-input-${i}`}
                        style={{
                          width: '100%',
                          height: '56px',
                          backgroundColor: '#fff',
                          borderRadius: '4px',
                          padding: '12px',
                          cursor: 'pointer',
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            color: '#000',
                            flex: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {displayName}
                        </span>

                        {/* แสดง "View" ก็ต่อเมื่อมีไฟล์ใหม่ หรือมี slot.url (URL รูปเก่า) */}
                        {(files[i] || slot.url) && (
                          <a
                            href={files[i] ? URL.createObjectURL(files[i]) : slot.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: '#007BFF',
                              textDecoration: 'underline',
                              marginRight: 10,
                            }}
                          >
                            View
                          </a>
                        )}

                        {(files[i] || slot.url) && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // เคลียร์ไฟล์ใหม่ใน files[]
                              handleFileChange(i, null);
                              // เคลียร์ไฟล์เก่า
                              slot.setUrl('');
                              slot.setName('');
                            }}
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              cursor: 'pointer',
                              marginLeft: 10,
                            }}
                          >
                            <span
                              style={{
                                border: '1px solid #FF0000',
                                borderRadius: '50%',
                                padding: '4px',
                                color: '#FF0000',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                              }}
                            >
                              X
                            </span>
                          </button>
                        )}
                      </label>
                    </div>
                  </div>
                );
              })}
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF'}} onClick={()=>navigate('/profile', { state: { startIndex, endIndex } })}>ย้อนกลับ</button>
              </div>

            </div>
          </div>
        </main>
      </div>
      
    
  );
}

export default ProfileUpSk;

  
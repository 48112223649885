import React, { useState,useEffect,useCallback,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from 'react-router-dom';
import Sidebar from './sidebar';
import './Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import './addProfile.css'
import { TextField } from '@mui/material';
import firestore from './Firebase/Firestore';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import './FilePicker.css';
import { useDropzone } from 'react-dropzone';
import storage from './Firebase/Storage';
import Layout from './Layout';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from './UserContext';
import { name } from 'dayjs/locale/th';


function AnnouceEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const [title,setTitle] = useState('');
  const [link,setLink] = useState('');
  const [date,setDate] = useState(dayjs());
  const [detail,setDetail] = useState('');
  const [selectID,setSelectID] = useState('');
  const [fileName,setFileName] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [oldFileURL, setOldFileURL] = useState('');
  const [type,setType] = useState('');
  const [count,setCount] =useState(null);
  const [imageFile,setImageFile] = useState([]); 
  const [oldImageURL, setOldImageURL] = useState('');
  const [oldImageName, setOldImageName] = useState('');
  const [oldImageFile, setOldImageFile] = useState('');
  // รูปใหม่ (ถ้ามีผู้ใช้เลือกอัปโหลด) => เป็น File object (หรือ null)
  const [newImageFile, setNewImageFile] = useState(null);

  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const { setCurrentUser, companyId } = useContext(UserContext);

  const types =[
   
    {label:'ประกาศฉุกเฉิน',value:1},
    {label:'ข่าวสาร',value:2},
    {label:'กฎระเบียบ',value:3},
    {label:'ข่าวสารทั่วไป',value:4},
    // {label:'รณรงค์ลดโลกร้อน',value:5},
    {label:'ปฏิทินวันหยุด',value:6}
  ]

  
  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  }, [files]);


  const onDropImage = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert("You can only upload one file.");
      return;
    }
    
    const isImage = acceptedFiles.every((file) => file.type.startsWith("image/"));
    if (!isImage) {
      alert("Only image files are allowed.");
      return;
    }
  
    setNewImageFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps: getRootPropsImage,
    getInputProps: getInputPropsImage,
  } = useDropzone({
    onDrop: onDropImage,
    accept: 'image/*',   // รับเฉพาะรูปภาพ
    multiple: false,     // จำกัด 1 ไฟล์
  });

  const removeNewImageFile = () => {
    setNewImageFile(null);
  };

  // ลบไฟล์รูปเก่าที่มี URL => เราจะล้างค่าใน state และ (optionally) เรียก storage.deleteFile
  const removeOldImage = async () => {
    // if (oldImageURL) {
    //   await storage.deleteFile(oldImageURL); // ถ้าต้องการลบไฟล์เก่าทิ้งเลย
    // }
    setOldImageURL('');
    setOldImageName('');
  };


  const getAnnouceSuc=(data)=>{
    setTitle(data.title)
    setLink(data.link)
    setDate(dayjs(data.date,'DD-MM-YYYY'))
    setDetail(data.detail)
    setFileName(data.file_name)
    setFileURL(data.file)
    setOldFileURL(data.file)
    setType(data.type)
    setCount(data.count)
    setOldImageURL(data.imageFile || '');
    setOldImageName(data.imageFileName || '');
    setOldImageFile(data.imageFile || '');
  }

  const getAnnouceUnsuc=(e)=>{
    console.log('f edit'+e)
  }

  const updateAnnouceSuc=()=>{
    firestore.addAnnouceState(companyId,type)
    navigate('/annouce')
  }

  const updateAnnouceUnsuc=(error)=>{
    console.log(error)
  }

  const deleteFile = async (url) => {
    storage.deleteFile(url);
  };

  const onSave=async()=>{
    let date_str = date.format('DD/MM/YYYY');
    // สร้าง object item สำหรับอัปเดต
    let item = {
      title: title,
      link: link,
      detail: detail,
      date: date_str,
      file: fileURL,        // ไฟล์เอกสารเดิม
      file_name: fileName,  // ชื่อไฟล์เอกสารเดิม
      count: count,
      type: type,
      // รูปภาพ (ค่าเริ่มต้น: ใช้รูปเก่าที่เหลืออยู่)
      imageFile: oldImageURL,
      imageFileName: oldImageName
    };

    // ------- 1) จัดการไฟล์เอกสาร (ถ้ามีอัปโหลดใหม่) -------
    if (files.length > 0) {
      const file = files[0];
      // ลบไฟล์เอกสารเดิมก่อน (ถ้ามี)
      if (fileURL) {
        await deleteFile(fileURL);
      }
      // อัปโหลดไฟล์ใหม่
      storage.uploadFile(
        companyId,
        file,
        (progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }));
        },
        async (downloadURL) => {
          // อัปโหลดสำเร็จแล้ว set ใน item
          item.file = downloadURL;
          item.file_name = file.name;

          // เสร็จแล้วไปจัดการรูปภาพต่อ (ดูว่าผู้ใช้เลือกรูปใหม่หรือไม่)
          await handleImageUpload(item);
        },
        (error) => {
          console.error('Upload file (document) failed:', error);
        }
      );
    } else {
      // ไม่มีไฟล์ใหม่ หรือไม่ได้ลบไฟล์เดิม => ใช้ของเดิม
      // ไปจัดการรูปภาพต่อ
      deleteFile(oldFileURL)
      await handleImageUpload(item);
    }
  }

  const handleImageUpload = async (item) => {
    // มี newImageFile (File) ไหม -> ถ้ามี แปลว่าผู้ใช้เลือกอัปโหลดรูปใหม่
    if (newImageFile) {
      // ลบรูปเก่าก่อน (ถ้ายังมีอยู่)
      if (oldImageURL) {
        await deleteFile(oldImageURL);
      }
      // จากนั้นอัปโหลดรูปใหม่
      storage.uploadFile(
        companyId,
        newImageFile,
        (progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [newImageFile.name]: progress,
          }));
        },
        async (downloadURL) => {
          // อัปโหลดรูปใหม่สำเร็จ -> set URL และชื่อไฟล์ใน item
          item.imageFile = downloadURL;
          item.imageFileName = newImageFile.name;

          // อัปเดต Firestore
          await firestore.updateAnnouce(
            companyId,
            selectID,
            item,
            updateAnnouceSuc,
            updateAnnouceUnsuc
          );
        },
        (error) => {
          console.error('Upload image failed:', error);
        }
      );
    }
    else {
      // ไม่มี newImageFile
      // เช็คว่าผู้ใช้ลบรูปเก่าไปหรือเปล่า (oldImageURL กับ oldImageName อาจเป็นค่าว่างแล้ว)
      if (!oldImageURL) {
        // ถ้าลบรูปเก่าไปแล้ว => เซ็ตใน Firestore เป็นค่าว่าง
        deleteFile(oldImageFile)
        item.imageFile = '';
        item.imageFileName = '';

      }

      // อัปเดต Firestore ทันที
      await firestore.updateAnnouce(
        companyId,
        selectID,
        item,
        updateAnnouceSuc,
        updateAnnouceUnsuc
      );
    }
  };

  const removeExistingFile = async () => {
    //await deleteFile(fileURL);
    setFileName('');
    setFileURL('');
  };


  useEffect(() => {
    if (location.state && location.state.id) {
      setSelectID(location.state.id);
      //console.log('from eff'+uid)
      firestore.getAnnouce(companyId,location.state.id,getAnnouceSuc,getAnnouceUnsuc)
    } else {
      console.warn('No ID found in location state');
    }
  }, [location.state]);

  const removeFile = (file) => () => {
    //storage.deleteFile(file.file)
    console.log(file)
    setFiles(files.filter((f) => f !== file));
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[file.name];
      return newProgress;
    });
  };

  const {
    getRootProps: getRootPropsFile,
    getInputProps: getInputPropsFile,
  } = useDropzone({
    onDrop,
  });

  const isEmergencyType = type === 1;
  const isInternalType = type === 2;
  const isRuleType = type === 3;
  const isGeneralType = type === 4;
  const isCampaignType = type === 5;
  const isHolidayType = type === 6;

  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>แก้ไขประกาศ</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain" style={{overflowX:'hidden'}}>
              <div className='block_img'>
                {/* <img src='https://i.postimg.cc/YChjY7Pc/image-10.png' width={150} height={150} alt="Logo" /> */}
              </div>
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}}>
                <div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <TextField
                    label="หัวข้อ"
                    className="form-field"
                    variant="filled"
                    style={{width:'100%'}}
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  
                   
                  
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <TextField
                        label="ประเภทประกาศ"
                        className="form-field"
                        select
                        variant="filled"
                        style={{width:'100%'}}
                        InputLabelProps={{ style: { color: '#000' } }}
                        
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >{types.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                      </TextField>
                    
                  
                </div>
                <div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        
                        <DatePicker
                        label="วันที่ลงประกาศ"
                        className="form-field"
                        sx={{
                          width: '30%',
                          '& .MuiInputBase-root': {
                            backgroundColor: '#fff',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#000',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#000',
                          },marginRight:'1%'
                        }}
                        value={dayjs(date, 'dd/mm/yy')}
                        onChange={(newValue) => setDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="dd/MM/YYYY"
                        />
                
                    </LocalizationProvider>

                    <TextField
                        label="ลิงค์แนบ"
                        variant="filled"
                        className="form-field"
                        style={{width:'69%'}}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        disabled={isEmergencyType||isRuleType||isHolidayType}
                    />
               
                    
                    
                </div>
                <div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <TextField
                        label="รายละเอียด"
                        className="form-field"
                        variant="filled"
                        multiline
                        rows={4}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        style={{width:'100%'}}
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                        disabled={isInternalType||isRuleType||isGeneralType||isCampaignType||isHolidayType}
                    >
                    </TextField>
                </div>
                {(!isEmergencyType && !isInternalType && !isGeneralType)&&<div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <div className="file-picker">
                  <div {...getRootPropsFile({ className: 'dropzone' })}>
                          <input {...getInputPropsFile()} />
                    <p style={{fontSize:22}}>Drag and drop some files here, or click to select files</p>
                  </div>
                  <div className="file-list">
                  {files.length > 0 ? (
                      files.map((file, index) => (
                        <div key={index} className="file-item">
                          <span>{file.name}</span>
                          <span>{Math.round(uploadProgress[file.name] || 0)}%</span>
                          <button onClick={removeFile(file)}>Remove</button>
                        </div>
                      ))
                    ) : (
                      fileName && (
                        <div className="file-item">
                          <a href={fileURL} target="_blank" rel="noopener noreferrer">
                            {fileName}
                          </a>
                          <button onClick={removeExistingFile}>Remove</button>
                        </div>
                      )
                    )}
                  </div>
                </div>
                </div>}
                {(!isEmergencyType )&&<div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <div className="file-picker">
                    <div {...getRootPropsImage({ className: 'dropzone' })}>
                        <input {...getInputPropsImage()} />
                      <p style={{fontSize:22}}>Drag and drop image preview here, or click to select files</p>
                    </div>
                    <div className="file-list">
                    {newImageFile ? (
                        <div className="file-item">
                          <span>{newImageFile.name}</span>
                          <span style={{ marginLeft: 10 }}>
                            {Math.round(uploadProgress[newImageFile.name] || 0)}%
                          </span>
                          <button onClick={removeNewImageFile} style={{ marginLeft: 10 }}>
                            Remove
                          </button>
                        </div>
                      ) : (
                        // ถ้าไม่มี newImageFile แต่ยังมีรูปเก่า -> แสดงรูปเก่า
                        oldImageURL && (
                          <div className="file-item">
                            <a href={oldImageURL} target="_blank" rel="noopener noreferrer">
                              {oldImageName || 'ดูรูปเก่า'}
                            </a>
                            <button onClick={removeOldImage} style={{ marginLeft: 10 }}>
                              Remove
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>}
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
              <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF'}} onClick={()=>navigate('/annouce')}>ยกเลิก</button>
              </div>

            </div>
          </div>
        </main>
      </div>
      
    
  );
}

export default AnnouceEdit;

  
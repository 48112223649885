//AssetSync.js
import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './Home.css';
import Sidebar from './sidebar';
import './Profile.css';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useNavigate,useLocation } from 'react-router-dom';
import firestore from './Firebase/Firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { IoFilterOutline } from "react-icons/io5";
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Layout from './Layout';
import { UserContext } from './UserContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFilter, AiOutlineExport } from "react-icons/ai";

function AssetSync() {
  const navigate = useNavigate();
  const location = useLocation();
  const [allAssets, setAllAssets] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [showDel, setShowDel] = useState(false);
  const [selectID, setSelectID] = useState();
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [showFillter,setShowFillter] = useState(false);
  const [selectFillter,setSelectFillter] = useState('');
  const [type,setType] = useState('');
  const [name,setName] = useState('');
  const [id,setID] = useState('');
  const [sortOrderName, setSortOrderName] = useState('asc'); // Track sorting order for names
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const { account, companyId } = useContext(UserContext);
  
 

  
  const handleFillterClose = () => setShowFillter(false);
  const handleFilterShow = () => { 
    setShowFillter(true);
  }

  const handleDelClose = () => setShowDel(false);
  const handleDelShow = (id,name) => {
    setSelectID(id)
    setName(name)
    setShowDel(true);
  }

  const onAdd =()=>{
    navigate('/asset_add',{state:{action:'add',uid:id||'',startIndex, endIndex,  search,
        searchQuery,
        }})
  }

  const onEdit =(id)=>{
    console.log(id)
    navigate(`/asset_edit/${id}`, {state: {action: 'edit',uid:id||'',startIndex,endIndex,search,searchQuery,type}});
  }

  const fetchDropdownOptions = async () => {
    try {
      
      const assetTypeOptions = await firestore.getDropdownOptions(companyId,'asset_type')
      setAssetTypeOptions(assetTypeOptions.map(option => option.name));
      //console.log(positionOptions)
      
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  const applyFilters = () => {
    let filtered = allAssets;
    
    if (searchQuery) {
      filtered = filtered.filter(asset =>
        asset.name.toLowerCase().includes(searchQuery) ||
        asset.type.toLowerCase().includes(searchQuery) ||
        asset.date.toLowerCase().includes(searchQuery)
      );
    }
    
    if (type) {
      filtered = filtered.filter(asset => asset.type.toLowerCase().includes(type.toLowerCase()));
    }
    
    setFilteredAssets(filtered);
    if(search){
      setStartIndex(0);
      setEndIndex(10);
    }
    
  };

  useEffect(() => {
    if (location.state) {
      setStartIndex(location.state.startIndex !== undefined ? location.state.startIndex : 0);
      setEndIndex(location.state.endIndex !== undefined ? location.state.endIndex : 10);
      setSearch(location.state.search || '');
      setSearchQuery(location.state.searchQuery || '');
      setType(location.state.type || '');
    } else {
      setStartIndex(0);
      setEndIndex(10);
    }
    //firestore.getAllUser(companyId,getAllUsersSuccess,getAllUsersUnsuccess)
    fetchDropdownOptions();
  }, [location.state]);

  useEffect(() => {
    const unsubscribe = firestore.getAllAsset(companyId, (assets) => {
      // แปลงวันที่จาก string เป็น Date object เพื่อให้สามารถเรียงได้
      const parsedAssets = assets.map(asset => {
        const [day, month, year] = asset.date.split('/');
        // สร้าง Date object (เดือนเริ่มต้นที่ 0)
        return {
          ...asset,
          parsedDate: new Date(`${year}-${month}-${day}`)
        };
      });
  
      // เรียงลำดับ descending โดยใช้วันที่ (ล่าสุดมาก่อน)
      parsedAssets.sort((a, b) => b.parsedDate - a.parsedDate);
  
      setAllAssets(parsedAssets);
      setFilteredAssets(parsedAssets);
    }, (error) => {
      console.error("Error fetching assets:", error);
    });
    
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [companyId]);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, allAssets]);

  const onNext = () => {
    setStartIndex(startIndex + 10); // Increment the start index by 5
    setEndIndex(endIndex + 10); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 10, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 10, 10)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setSearch(event.target.value);
  };

  const handleFillter = () => {
    applyFilters();
    setShowFillter(false);
  };

  // Sorting function for names
  const sortByName = () => {
    const newOrder = sortOrderName === 'asc' ? 'desc' : 'asc';
    setSortOrderName(newOrder);
    const sortedAssets = [...filteredAssets].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return newOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return newOrder === 'asc' ? 1 : -1;
      return 0;
    });
    
    setFilteredAssets(sortedAssets);
  };

  const handleSelectAsset = (assetId) => {
    if (selectedAssets.includes(assetId)) {
      setSelectedAssets(selectedAssets.filter(id => id !== assetId));
    } else {
      setSelectedAssets([...selectedAssets, assetId]);
    }
  };

  const handleSelectAll = () => {
    const currentPageAssetIds = filteredAssets.slice(startIndex, endIndex).map(asset => asset.id);
    const allSelected = currentPageAssetIds.every(id => selectedAssets.includes(id));
    
    if (allSelected) {
      // Deselect all
      setSelectedAssets(selectedAssets.filter(id => !currentPageAssetIds.includes(id)));
    } else {
      // Select all
      const newSelected = [...new Set([...selectedAssets, ...currentPageAssetIds])];
      setSelectedAssets(newSelected);
    }
  };

  const handleDeleteShow = (asset) => {
    setSelectedAsset(asset);
    setShowDel(true);
  };

  const handleDeleteClose = () => {
    setSelectedAsset(null);
    setShowDel(false);
  };

  const deleteAsset = () => {
    if (selectedAsset) {
     
      firestore.deleteAsset(companyId, selectedAsset.id)
        .then(() => {
          console.log('Asset deleted successfully');
          handleDeleteClose();
        })
        .catch(error => {
          console.error('Error deleting asset:', error);
        });
    }
  };

  const bulkDeleteAssets = () => {
    if (selectedAssets.length === 0) {
      alert('Please select at least one asset to delete.');
      return;
    }
    
    // ยืนยันก่อนลบ
    const confirmed = window.confirm('Are you sure you want to delete the selected assets?');
    if (!confirmed) return;
  
    selectedAssets.forEach(assetId => {
      firestore.deleteAsset(companyId, assetId)
        .then(() => {
          console.log(`Asset ${assetId} deleted successfully`);
        })
        .catch(error => {
          console.error(`Error deleting asset ${assetId}:`, error);
        });
    });
    
    // Clear selected assets after deletion
    setSelectedAssets([]);
  };

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
          <div className='header-page'>
          <header>
            <h1 >Asset Sync</h1>
            {/* Add user profile and logout here */}
          </header>
          </div>
            <div className="main-contain">

              <div className="search-field">
                {/* <p style={{marginTop:17}}>ค้นหาพนักงาน</p> */}
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                {/*<button className="search-button" ><IoSearchOutline size={24} /></button>*/}
              </div>
              <div style={{display:'flex',flexDirection:'row', justifyContent: 'space-between',alignItems: 'center',marginTop:-5,marginBottom:10,width:'95%',alignSelf:'center'}}>
                <AiOutlineDelete
                    size={50}
                    className="trash-icon"
                    onClick={bulkDeleteAssets}
                    style={{     
                      // marginBottom: '10px',    
                      cursor: 'pointer',       
                      color: 'red',            
                      border: '2px solid red', 
                      padding: '5px',          
                      borderRadius: '5px',  
                      marginRight:10
                    }}
                  />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <button 
                    style={{ borderRadius: 10, marginRight: 10,width:50,height:50 }} 
                    onClick={() => setShowFillter(true)}
                  >
                    <IoFilterOutline size={20} />
                  </button>
                  <button 
                    className='Add-button' 
                    style={{marginBottom:0}}
                    onClick={onAdd}
                  >
                    เพิ่มรายการ
                  </button>
                </div>
              </div>
              
              <div style={{width:'95%',alignSelf:'center'}}>
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          filteredAssets.slice(startIndex, endIndex).length > 0 &&
                          filteredAssets.slice(startIndex, endIndex).every(asset => selectedAssets.includes(asset.id))
                        }
                      />
                    </th>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">วันที่</th>
                    <th scope="col" onClick={sortByName} style={{ cursor: 'pointer' }}>
                      ชื่อทรัพย์สิน{sortOrderName === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </th>
                    <th scope="col">ประเภท</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/*{allUser.slice(startIndex, endIndex).map((item, index) => (*/}
                  {filteredAssets.slice(startIndex, endIndex).map((asset, index) => (
                    <tr key={asset.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedAssets.includes(asset.id)}
                          onChange={() => handleSelectAsset(asset.id)}
                        />
                      </td>
                      <td>{startIndex + index + 1}</td>
                      {/* <th scope="row" style={{width:80}}>{index + 1}</th> */}
                      <td>{asset.date}</td>
                      <td>{asset.name}</td>
                      <td>{asset.type}</td>
                      <td style={{width:'30%',alignItems:'center',justifyContent:'center'}}>
                        <button
                          className='Edit-button'
                          onClick={() => onEdit(asset.id)}
                          style={{ marginRight: 10 }}
                        >
                          Edit
                        </button>
                        <button
                          className='Delete-button'
                          onClick={() => handleDeleteShow(asset)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              <div>
                <button className='Previous-button' onClick={onPrevious} disabled={startIndex === 0}>Previous</button>
                <button className='Next-button' onClick={onNext} disabled={endIndex >= filteredAssets.length}>Next</button>
                </div>
              </div>

            </div>
          </div>
        </main>

        <Modal show={showFillter} onHide={() => setShowFillter(false)}>
        <Modal.Header closeButton>
          <Modal.Title>เลือกข้อมูลที่ต้องการกรอง</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="filled-select"
            select
            label="ประเภท"
            variant="filled"
            style={{ width: 300, marginRight: 10 }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            SelectProps={{
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true, // Ensures the label is always shrunk
            }}
          >
            <MenuItem value="">None / Clear Filter</MenuItem>
            {assetTypeOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ backgroundColor: '#D3D3D3', color: 'black' }}
            onClick={handleFillter}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            style={{ backgroundColor: '#343434' }}
            onClick={() => setShowFillter(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDel} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAsset && (
            <p>Are you sure you want to delete the asset "{selectedAsset.name}"?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={deleteAsset}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      
    
  );
}

export default AssetSync;

  